export default (value: string, $t: any) => {
  function prettyEndDate() {
    const month = extractMonth(value)
    // eslint-disable-next-line ts/no-unsafe-call
    const formattedMonth = $t(month) as string
    const formattedEndDate = removeMonth(value, month)
    const prettyEndDate = `${formattedEndDate[0]} ${formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1)} ${formattedEndDate[1]}`

    if (!isSpanishMonth(formattedMonth))
      return value

    return prettyEndDate
  }
  function extractMonth(value: string) {
    const months = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ]

    const lowercaseMonth = value.toLowerCase()

    for (const month of months) {
      if (lowercaseMonth.includes(month)) {
        return month
      }
    }

    return ''
  }
  function removeMonth(input: string, month: string) {
    const regex = new RegExp(month, 'i')
    return input.replace(regex, '').trim().split(',').map(s => s.trim())
  }
  function isSpanishMonth(month: string) {
    const spanishMonths = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ]
    const normalizedMonth = month.trim().toLowerCase()

    return spanishMonths.includes(normalizedMonth)
  }

  return prettyEndDate()
}
